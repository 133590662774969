import React from 'react';
import sectiondata from '../../data/sections.json';

const Screenshot = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imgattr = 'image'

    return <div>
            <div className="sba-screenshot-area pd-top-112">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-9">
                            <div className="section-title text-center">
                                <h2 className="title">{sectiondata.screenshots.sectiontitle} <span>{sectiondata.screenshots.sectiontitle_color}</span></h2>
                                <p>{sectiondata.screenshots.short_description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="screenshot-slider">
                        {sectiondata.screenshots.images.map( ( item, i ) =>
                            <div key={i} className="screenshot-slider-item">
                                <div className="thumb">
                                    <img src={item} alt={imgattr}/>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default Screenshot